.padding {
  padding-top: var(--container-spacing);
  padding-bottom: var(--container-spacing);
  padding-left: var(--container-spacing);
  padding-right: var(--container-spacing);
}

.padding-l {
  padding-left: var(--container-spacing);
}

.padding-r {
  padding-right: var(--container-spacing);
}

.padding-t {
  padding-top: var(--container-spacing);
}

.padding-b {
  padding-bottom: var(--container-spacing);
}

.padding-x {
  padding-left: var(--container-spacing);
  padding-right: var(--container-spacing);
}

.padding-y {
  padding-top: var(--container-spacing);
  padding-bottom: var(--container-spacing);
}

/* Layer */

.bowl {
  width: 100%;
  padding-left: var(--container-spacing);
  padding-right: var(--container-spacing);
}

.content {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--content-max-width);
}

.bowl-content {
  width: 100%;
  padding-left: var(--container-spacing);
  padding-right: var(--container-spacing);
  margin-left: auto;
  margin-right: auto;
  max-width: calc(var(--content-max-width) + (var(--container-spacing) * 2));
}

/* */

.break-word {
  hyphens: auto;

  word-wrap: break-word;
  word-break: break-word;

  overflow-wrap: break-word;
}

.text-gradient {
  background-clip: text;
  background-size: 100%;
  background-color: var(--colors-white);

  -ms-background-clip: text;
  -moz-background-clip: text;
  -webkit-background-clip: text;

  -ms-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

.scrollbar-custom {
  --scrollbar-gap: 0.5rem;
  --scrollbar-width: 1.8rem;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  min-height: 4rem;
  background-color: #c9c9c9;
}

.scrollbar-custom::-webkit-scrollbar-thumb,
.scrollbar-custom::-webkit-scrollbar-track {
  border-radius: 999px;
  border: var(--scrollbar-gap) solid transparent;
  background-clip: padding-box;
}

.scrollbar-custom::-webkit-scrollbar-corner,
.scrollbar-custom::-webkit-scrollbar-track {
  background-color: transparent;
}

&::-webkit-scrollbar {
  width: var(--scrollbar-width);
}
